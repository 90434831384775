const Utils = require('./utils');
const SharedConstants = require('relevant-shared/misc/sharedConstants');

let Handlers;
let bidderAliases;
let byBidderInfo;
const handlerByBidder = {};

class BidderHandler {
	constructor(settings) {
		Utils.assign(this, settings);
	}

	setFloor({ params, bidFloor, bidFloorCurrency = 'USD' }) {
		if (this.bidFloorField) {
			if (bidFloor === null) { // We're removing the floor
				delete params[this.bidFloorField];
				if (this.bidFloorCurrencyField) {
					delete params[this.bidFloorCurrencyField];
				}
				return;
			}
			params[this.bidFloorField] = this.bidFloorFieldIsString ? bidFloor.toString() : bidFloor;
			if (this.bidFloorCurrencyField) {
				params[this.bidFloorCurrencyField] = bidFloorCurrency;
			}
		}
	}

	isInvalid() {
		return false;
	}

	static init({ aliases, s2sAliases, byBidderInfo: byBI }) {
		bidderAliases = { ...aliases, ...s2sAliases };
		byBidderInfo = byBI;
	}

	static of({ bidder }) {
		const name = bidderAliases[bidder] || bidder;
		let handler = handlerByBidder[name];
		if (!handler) {
			const info = byBidderInfo[name];
			const Handler = Handlers[info.type] || BidderHandler;
			handler = new Handler(info);
			handlerByBidder[name] = handler;
		}
		return handler;
	}

	addRuntimeBidParams() {}

	tryUseWithoutSplit({ bidder }, { seenBidders }) {
		if (seenBidders[bidder]) {
			return false;
		}
		seenBidders[bidder] = true;
		return true;
	}
}

class AmazonSsp extends BidderHandler {
	isInvalid() {
		return true; // Never include "bid" in Prebid bids
	}
}

class AdagioSsp extends BidderHandler {
	addRuntimeBidParams({ params }, { slot }) {
		params.site = params.site || Utils.getLocation()?.hostname.replace(/\./g, '-');
		params.adUnitElementId = slot.getSlotElementId();
	}
}

class PubmaticSsp extends BidderHandler {
	finalizeBids(bids) {
		const withCurrency = Utils.find(bids, (b) => b.params.currency);
		if (withCurrency) {
			bids.forEach((bid) => {
				bid.params.currency = withCurrency.params.currency;
			});
		}
	}
}

class HbmEmptyBidder extends BidderHandler {
	isInvalid() {
		return true;
	}
}

Handlers = {
	HbmEmptyBidder,
	AmazonSsp,
	AdagioSsp,
	PubmaticSsp,
};

const toExport = {
	init: BidderHandler.init,
	of: BidderHandler.of,
};

window.relevantDigital.export({ BidderHandler: toExport });

module.exports = toExport;
