const AS_FLAGS = [
	'timedOut',
	'noBid',
	'bidWon',
	'bidResponse',
	'renderFailed',
	'renderSuccess',
	'unloadBeforeResponse',
	'isVideo',
	'isNative',
	'isDeal',
	'isUniqueResponse',
	'isHighestNonWinningBid',
	'adserverWon',
	'isDirect',
	'isApproximation',
	'isAdserverBid',
]; // order must be synced on server-side

const DEAL_TYPES = {
	DYNAMIC: 0,
	DIRECT: 1,
	DEAL: 2,
	HOUSE: 3,
};

const SYSTEM_PARAM_PREFIX = '_sys_';
const PREBID_CONFIGURATION_NAME = 'Prebid Configuration';
const PREBID_CONFIGURATION_CLIENT_DIM = `${SYSTEM_PARAM_PREFIX}${PREBID_CONFIGURATION_NAME}`;
const PREBID_CONFIGURATION_DIM = `_${PREBID_CONFIGURATION_CLIENT_DIM}`;
const GAM_DUPLICATE_PATH_SEPARATOR = '_rlv_dup_';
const MAX_DUPL_FILTER_NUM = 10; // When filtering by placement, highest _rlv_dupl_[N] to check for
const MAX_HBA_GET_PARAM_CHARS = 10 * 1024;
const S2S_BIDDER_SUFFIX = '_s2s_alias';
const NO_OPTIMIZATION = -1;
const DEF_FLOOR = 0.00001; // To set as floor instead of zero, as floor-price-module doesn't like zeroes..

// Custom dimension name + enabled value + disabled value (will not be set when disabled but reportable)
const OPTI_DIM_SETTINGS = ['Optimization', 'Enabled', 'Disabled'];
const MAX_FILE_UPLOAD_SIZE = 1 * 1024 * 1024; // 1MB :)

const EVENTS = [
	'auctionInit',
	'auctionEnd',
	'bidAdjustment',
	'bidTimeout',
	'bidRequested',
	'bidResponse',
	'noBid',
	'bidWon',
	'bidderDone',
	'setTargeting',
	'requestBids',
	'addAdUnits',
	'adRenderFailed',
];

const DEFAULT_PLACEMENT_PATH = '?';

module.exports = {
	AS_FLAGS,
	DEAL_TYPES,
	SYSTEM_PARAM_PREFIX,
	PREBID_CONFIGURATION_NAME,
	PREBID_CONFIGURATION_CLIENT_DIM,
	PREBID_CONFIGURATION_DIM,
	GAM_DUPLICATE_PATH_SEPARATOR,
	MAX_DUPL_FILTER_NUM,
	EVENTS,
	DEFAULT_PLACEMENT_PATH,
	MAX_HBA_GET_PARAM_CHARS,
	S2S_BIDDER_SUFFIX,
	NO_OPTIMIZATION,
	OPTI_DIM_SETTINGS,
	DEF_FLOOR,
	MAX_FILE_UPLOAD_SIZE,
};
