const Utils = {
	assign(dst) {
		for (let i = 1; i < arguments.length; i += 1) {
			const src = arguments[i];
			if (src) {
				for (const key in src) {
					dst[key] = src[key];
				}
			}
		}
		return dst;
	},
	entries(obj) {
		const res = [];
		for (const k in obj || {}) {
			res.push([k, obj[k]]);
		}
		return res;
	},
	find: function find(arr, cb) {
		for (let i = 0; i < arr.length; i += 1) {
			if (cb(arr[i])) {
				return arr[i];
			}
		}
		return undefined;
	},
	fmt(num, n, base) {
		let res = num.toString(base || 10);
		for (let i = n - res.length; i > 0; i -= 1) {
			res = `0${res}`;
		}
		return res;
	},
	values(obj) {
		if (Object.values) {
			return Object.values(obj);
		}
		const res = [];
		for (const key in obj) {
			res.push(obj[key]);
		}
		return res;
	},
	makeUrl(str) {
		let res = str.toLowerCase().trim();
		if (res.indexOf('http://') === 0) {
			res = res.slice(5);
		} else if (res.indexOf('https://') !== 0) {
			res = `//${res}`;
		}
		return res;
	},
	uniqId() {
		return (Date.now() % 4294967296)
			.toString(16)
			+ Utils.fmt(Math.floor((Math.random() * 4294967296)), 8, 16);
	},
	isObject(val, checkNoArr) {
		const type = typeof val;
		return val !== null && type === 'object' && !(checkNoArr && Array.isArray(val));
	},
	isString(val) {
		return (typeof val === 'string' || val instanceof String);
	},
	merge(dst, ...sources) {
		return Utils.mergeSources(dst, sources, false);
	},
	mergeNoArr(dst, ...sources) {
		return Utils.mergeSources(dst, sources, true);
	},
	mergeSources(dst, sources, noArr) {
		sources.forEach((orgSrc) => {
			if (orgSrc) {
				const src = Utils.clone(orgSrc);
				for (const key in src) {
					if (Utils.isObject(dst[key], noArr) && Utils.isObject(src[key], noArr)) {
						Utils.mergeSources(dst[key], [src[key]], noArr);
					} else {
						dst[key] = src[key];
					}
				}
			}
		});
		return dst;
	},
	clone(obj) {
		if (Array.isArray(obj)) {
			return obj.map(Utils.clone);
		}
		if (Utils.isObject(obj)) {
			const res = {};
			for (const key in obj) {
				res[key] = Utils.clone(obj[key]);
			}
			return res;
		}
		return obj;
	},
	loadScript(src) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = 'async';
		script.src = src;
		const firstScript = document.getElementsByTagName('script')[0];
		firstScript.parentNode.insertBefore(script, firstScript);
	},
	processQueue(obj, queueVar) {
		const queue = obj[queueVar];
		obj[queueVar] = { push: (fn) => fn() };
		queue.forEach((fn) => {
			try {
				fn();
			} catch (e) {
				console.error(`Error in handler: ${e}`);
			}
		});
	},
	onceEvent() {
		const obj = { cmd: [] };
		let triggered;
		let result;
		return {
			wait: (cb) => obj.cmd.push(() => cb(result)),
			done: () => !!triggered,
			trigger: (withResult) => {
				if (!triggered) {
					triggered = true;
					result = withResult;
					Utils.processQueue(obj, 'cmd');
				}
			},
		};
	},
	attribToBool(elm, name, defVal) {
		if (!elm.hasAttribute(name)) {
			return !!defVal;
		}
		const val = elm.getAttribute(name);
		return val !== '0' && val !== 'false';
	},
	withGoogle(fn) {
		window.googletag = window.googletag || {};
		const { googletag } = window;
		googletag.cmd = googletag.cmd || [];
		googletag.cmd.push(() => fn(googletag));
	},
	uniq(arr) {
		const seen = {};
		const res = [];
		arr.forEach((e) => {
			if (!seen[e]) {
				seen[e] = true;
				res.push(e);
			}
		});
		return res;
	},
	cleanMcmPart(adUnitPath) {
		if ((adUnitPath || '')[0] !== '/') {
			return adUnitPath;
		}
		const commaIdx = adUnitPath.indexOf(',');
		if (commaIdx < 0) {
			return adUnitPath;
		}
		const rest = adUnitPath.substring(commaIdx + 1);
		const restIdx = rest.indexOf('/');
		return restIdx < 0 ? adUnitPath : adUnitPath.substring(0, commaIdx) + rest.substring(restIdx);
	},
	injectMcmPart(adUnitPath, childNwid) {
		if (childNwid && (adUnitPath || '')[0] === '/' && Utils.cleanMcmPart(adUnitPath) === adUnitPath) {
			const parts = adUnitPath.split('/');
			return `/${parts[1]},${childNwid}${parts.length > 2 ? '/' : ''}${parts.slice(2).join('/')}`;
		}
		return adUnitPath;
	},
	normalize(path, noLowerCase, wSlash) {
		let res = noLowerCase ? path.trim() : path.trim().toLowerCase();
		res = Utils.cleanMcmPart(res);
		if (wSlash) {
			return res[0] !== '/' && res.indexOf('/') > 0 ? `/${res}` : res;
		}
		return res[0] === '/' ? res.slice(1) : res;
	},
	normalizedPaths(paths, noLowerCase, wSlash) {
		return (paths || '')
			.split('\n')
			.map((s) => Utils.normalize(s, noLowerCase, wSlash))
			.filter((p) => p);
	},
	rlvConvertedGamAdUnitPath(slot, { customDimStr }) {
		let path = Utils.cleanMcmPart(slot.getAdUnitPath());
		if (customDimStr) {
			const vals = slot.getTargeting(customDimStr) || [];
			let val;
			if (vals.length === 0) {
				val = '_unset_';
			} else if (vals.length === 1) {
				[val] = vals;
			} else {
				val = '_multi_';
			}
			path += `/${val}`;
		}
		return path;
	},
	generateUUID() { // Mostly copy-paste from generateUUID() in Prebid
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (num) => (
			(num ^ (Math.random() * 16) >> num / 4).toString(16)
		));
	},
	withCatch(fn) {
		try {
			fn();
		} catch (e) {
			console.error(`${e}`);
		}
	},
	keyBy(arr, cond) {
		const res = {};
		if (typeof cond === 'function') {
			(arr || []).forEach((elm) => { res[cond(elm)] = elm; });
		} else if (Utils.isString(cond)) {
			(arr || []).forEach((elm) => { res[elm[cond]] = elm; });
		} else {
			(arr || []).forEach((elm) => { res[elm] = elm; });
		}
		return res;
	},
	/**
	 * Similar to a mutex, creates an object with a function 'wait(fn)' where the function passed as parameter
	 * will run when the previous "owner" has called the 'setAvailable()' function.
	 *
	 * [Example]
	 * const queue = Utils.waitQueue();
	 * queue.wait(() => {
	 *      console.info(1);
	 * 		setTimeout(() => queue.setAvailable(), 1000);
	 * });
	 * queue.wait(() => console.info(2));
	 * console.info(3);
	 *
	 * [Output]
	 * 1
	 * 3
	 * 2
	 */
	waitQueue() {
		const que = [];
		let avail = true;
		let res;
		const maybeRun = () => {
			if (avail && que.length) {
				avail = false;
				que.shift()(res);
			}
		};
		res = {
			wait(fn) {
				que.push(fn);
				maybeRun();
			},
			setAvailable() {
				avail = true;
				maybeRun();
			},
		};
		return res;
	},
	/**
	 * Helper to create before/after "hooks" for functions on objects, example:
	 *
	 *  const cbSet = callbackSet();
	 *	cbSet.add({
	 *		getElementById: function(id) {
	 *			console.info("BEFORE: ", this, id);
	 *		},
	 *	}); // default is 'before'
	 *	cbSet.add({
	 *		getElementById: function(result, id) {
	 *			console.info("AFTER: ", this, result, id);
	 *			return result;
	 *		},
	 *	}, { when: 'after' });
	 *	cbSet.apply(document);
	 *	const result = document.getElementById('backgroundImage');
	 */
	callbackSet() {
		const byType = {};
		const add = (cbs, { type = 'default', when: w } = {}) => {
			const when = w === 'after' ? 'after' : 'before';
			byType[type] = byType[type] || { seen: {}, before: [], after: [] };
			const typeInfo = byType[type];
			typeInfo[when].push(cbs);
			Object.keys(cbs).forEach((cbName) => {
				typeInfo.seen[cbName] = true;
			});
		};
		const apply = (dstObj, type = 'default') => {
			const { seen = {}, before, after } = byType[type] || {};
			Object.keys(seen).forEach((fnName) => {
				const existing = dstObj[fnName];
				dstObj[fnName] = (...args) => {
					before.forEach((cbs) => cbs[fnName] && cbs[fnName].call(dstObj, ...args));
					let res = existing && existing.call(dstObj, ...args);
					after.forEach((cbs) => {
						if (cbs[fnName]) {
							res = cbs[fnName].call(dstObj, res, ...args);
						}
					});
					return res;
				};
			});
		};
		return { add, apply };
	},
	storage(key = '_rlv_data') {
		let d;
		const MAX_STORAGE_LEN = 1024 * 1024;
		const getData = () => {
			if (!d) {
				d = {};
				const str = localStorage[key];
				if (str) {
					try {
						d = JSON.parse(str);
					} catch (e) { /** ignore.. */ }
				}
			}
			return d;
		};
		const write = () => {
			try {
				const str = JSON.stringify(getData());
				if (str.length <= MAX_STORAGE_LEN) {
					localStorage[key] = str;
				} else {
					console.warn('Skips writing too large value to local storage');
				}
			} catch (e) { /** ignore */ }
		};
		const update = (updateData) => { // optional helper function..
			Utils.merge(getData(), updateData);
			write();
		};
		const res = {
			get data() {
				return getData();
			},
			write,
			update,
		};
		res.urlCaches = Utils.urlCaches(res);
		return res;
	},
	/** eval() some 'code' and make 'vars' into local variables, Example:
	 * evalWithVars('console.info(message)', { message: 'hello' }) => "hello" */
	evalWithVars(code, vars = {}) {
		const id = `tmpEvalVars_${Math.random()}`;
		window[id] = vars;
		const varSetters = Object.keys(vars).map((key) => `var ${key} = data.${key};`).join('\n');
		eval(
			`(function(id) {
				var data = window[id];
				delete window[id];
				${varSetters}
				${code};
			})('${id}')`,
		);
	},
	runFns(fns) {
		let count = 0;
		const res = [];
		const doneEvent = Utils.onceEvent();
		fns.forEach((fn, idx) => fn((fnRes) => {
			res[idx] = fnRes;
			count += 1;
			if (count === fns.length) {
				doneEvent.trigger();
			}
		}));
		if (!fns.length) {
			doneEvent.trigger();
		}
		return { then: (cb) => doneEvent.wait(() => cb(res)) };
	},
	getLocation() {
		const { top, location } = window;
		if (window === top) {
			return location;
		}
		try {
			if (top.location.toString) {
				return top.location;
			}
		} catch (e) { /** ignore */ }
		return null;
	},

	hookBefore(fn, pre) {
		return function (...args) {
			pre.apply(this, args);
			return fn.apply(this, args);
		};
	},

	urlCaches(storage) {
		const obj = {};
		const onceLoader = (settings) => {
			const {
				url,
				storageKey,
				dataSubKey = 'data',
				useStorage = true,
				noReloadMs = 0,
				body,
				transform = (s) => s,
				deserialize = (s) => s,
			} = settings;
			let loadEvent;
			let age;
			const useS = useStorage && storage;
			const loadInternal = (doneCb, { waitIfOlderMs } = {}) => {
				if (!loadEvent) {
					loadEvent = Utils.onceEvent();
					const storedObj = useS && storage.data[storageKey];
					const { ts, [dataSubKey]: dataObj } = storedObj || {};
					const hasStored = typeof ts === 'number';
					age = new Date().getTime() - (parseInt(ts, 10) || 0);
					if (dataObj !== undefined) {
						Utils.withCatch(() => {
							obj[storageKey] = deserialize(dataObj);
						});
					}
					if (!hasStored || age > noReloadMs) {
						const xhr = new XMLHttpRequest();
						xhr.onload = () => {
							Utils.withCatch(() => {
								const res = transform(JSON.parse(xhr.responseText));
								obj[storageKey] = deserialize(res);
								age = 0;
								if (useS) {
									storage.update({
										[storageKey]: { [dataSubKey]: res, ts: new Date().getTime() },
									});
								}
							});
							loadEvent.trigger();
						};
						xhr.onerror = () => loadEvent.trigger();
						xhr.open('POST', url);
						xhr.send(JSON.stringify(body || {}));
					} else {
						loadEvent.trigger();
					}
				}
				if (!loadEvent.done()
					&& obj[storageKey] !== undefined
					&& !(waitIfOlderMs !== undefined && age > waitIfOlderMs)) {
					doneCb();
				} else {
					loadEvent.wait(doneCb);
				}
			};
			const load = (doneCb, timeoutMs, extraSettings) => {
				let doneCalled;
				const done = () => {
					if (!doneCalled) {
						doneCb?.(obj[storageKey], { age });
						doneCalled = true;
					}
				};
				if (!timeoutMs) {
					loadInternal(done, extraSettings);
					if (timeoutMs === 0) {
						done();
					}
				} else {
					const ev = Utils.onceEvent();
					setTimeout(timeoutMs, () => ev.trigger());
					loadInternal(() => ev.trigger(), extraSettings);
					ev.wait(done);
				}
			};
			return load;
		};
		return { onceLoader };
	},
	randomizer(initSeed = 0) {
		let seed = initSeed || 1;
		return () => {
			seed += 1;
			const x = Math.sin(seed) * 10000;
			return x - Math.floor(x);
		};
	},
};

module.exports = Utils;
